import { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Button, Cart, Typography } from 'common/widgets'
import useCart from 'hooks/useCart'
import classNames from './layout.module.scss'
import { useTranslations } from 'common/language'
import ModalTrigger from 'common/modals/ModalTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


const cartIcon = ['fal', 'shopping-cart']
const closeIcon = ['fal', 'times']

CartButton.propTypes = {
  withoutCart: PropTypes.bool.isRequired,
}

export default function CartButton({ withoutCart }) {
  const { gettext } = useTranslations()
  const cartData = useCart()
  const cartLength = useMemo(() => Object.keys(cartData.data).length, [cartData.data])
  if(withoutCart || !cartLength) {
    return null
  }
  return (
    <div className={classNames.triggerWrapper}>
      <ModalTrigger
        component={Cart}
        isFromSide
        width={500}
        modalClassName={classNames.cartModal}
        withLink
        closeIcon={<FontAwesomeIcon icon={closeIcon} size="lg" />}
        title={<Typography variant="h3" transform="uppercase">{gettext('Your choice')}</Typography>}
        mobileTitleText={gettext('Your choice')}
        forceRender
      >
        <Button
          icon={cartIcon}
          iconBadge={cartLength > 0 ? cartLength : undefined}
          className={classNames.sideNav}
        />
      </ModalTrigger>
    </div>
  )
}
